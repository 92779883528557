<template>
  <div
    class="group relative grid min-w-16 place-content-center rounded-md px-2 text-xs/5 font-semibold"
    :class="{
      'bg-vesper-accent text-white': byYou,
      'cursor-pointer bg-sky-100': isByLiquidityProvider,
      'bg-emerald-100': isBuyer,
      'bg-red-100': isSeller,
      'bg-amber-100': isTrader,
    }"
  >
    {{
      byYou
        ? $t('listing-preview.by-you')
        : isByLiquidityProvider
          ? $t('overview.liquidity-provider')
          : $t(`overview.${type}`)
    }}

    <span
      v-if="isByLiquidityProvider && !byYou"
      class="pointer-events-none absolute top-1/2 right-full z-10 mr-2 -translate-y-1/2 rounded-sm bg-slate-950 px-2 py-1 text-xs text-nowrap text-white opacity-0 transition-opacity group-hover:opacity-100"
    >
      {{ $t('overview.liquidity-provider-explanation') }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { CompanyType } from '@/types/company';

const props = defineProps<{
  byYou: boolean;
  type: CompanyType;
  isByLiquidityProvider?: boolean;
}>();

const isByLiquidityProvider = props.isByLiquidityProvider;
const isBuyer =
  !isByLiquidityProvider && !props.byYou && props.type === 'BUYER';
const isSeller =
  !isByLiquidityProvider && !props.byYou && props.type === 'SELLER';
const isTrader =
  !isByLiquidityProvider && !props.byYou && props.type === 'TRADER';
</script>
