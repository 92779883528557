import { useMarketStore } from '@/stores';
import { Market } from '@/types/market.ts';

export function useIsMarketOpen(market: Market): boolean {
  return market.status === 'OPEN';
}

export function useIsMarketScheduled(market: Market): boolean {
  return market.status === 'SCHEDULED';
}

export function useIsMarketFinalised(market: Market): boolean {
  return market.status === 'FINALISED';
}

export function useGetMarketDateReference(market: Market): Date {
  return new Date(market.status === 'OPEN' ? market.closeAt : market.startAt);
}

export function useDetermineSelectedMarketForMarketOverview(): Market | null {
  const marketStore = useMarketStore();

  if (marketStore.activeMarket && useIsMarketOpen(marketStore.activeMarket)) {
    return marketStore.activeMarket;
  }

  return marketStore.closedMarket ?? marketStore.activeMarket;
}
