import { Api, Stat } from '@/api/types';

type MetricCache = { [key: string]: { [key: string]: string[] } };

export class StatsApi {
  public cache: MetricCache = {};
  public buffer: Stat[] = [];

  public constructor(protected readonly client: Api) {}

  public async flush(): Promise<void> {
    if (this.buffer.length === 0) {
      return;
    }

    const payload = this.buffer;
    this.buffer = [];

    try {
      await this.client.post(`/stats`, payload);
    } catch (e) {
      this.buffer.push(...payload);
      throw e;
    }
  }

  public async clear(): Promise<void> {
    try {
      await this.flush();
    } finally {
      this.buffer = [];
    }
  }

  public collectListingOpened(listingId: number): void {
    this.buffer.push({
      activity: 'OPENED',
      subject: 'LISTING',
      subjectId: `${listingId}`,
    });
  }

  public collectListingClosed(listingId: number): void {
    this.buffer.push({
      activity: 'CLOSED',
      subject: 'LISTING',
      subjectId: `${listingId}`,
    });
  }

  /**
   * This denotes the action of clicking on the edit button.
   * **This does not guarantee that they follow through with editing the listing.**
   */
  public collectListingEditing(listingId: number): void {
    this.pushCached({
      activity: 'EDITING',
      subject: 'LISTING',
      subjectId: `${listingId}`,
    });
  }

  /**
   * This denotes the action of submitting the edited changes.
   */
  public collectListingEdited(listingId: number): void {
    this.pushCached({
      activity: 'EDITED',
      subject: 'LISTING',
      subjectId: `${listingId}`,
    });
  }

  /**
   * This denotes the action of clicking on the duplicate button.
   * **This does not guarantee that they follow through with duplicating the listing.**
   */
  public collectListingDuplicating(listingId: number): void {
    this.pushCached({
      activity: 'DUPLICATING',
      subject: 'LISTING',
      subjectId: `${listingId}`,
    });
  }

  /**
   * This denotes the action of submitting the duplicated listing.
   */
  public collectListingDuplicated(listingId: number): void {
    this.pushCached({
      activity: 'DUPLICATED',
      subject: 'LISTING',
      subjectId: `${listingId}`,
    });
  }

  public collectListingShown(listingId: number): void {
    this.pushCached({
      activity: 'SHOWN',
      subject: 'LISTING',
      subjectId: `${listingId}`,
    });
  }

  public collectProposalSeen(proposalId: number): void {
    this.pushCached({
      activity: 'SEEN',
      subject: 'PROPOSAL',
      subjectId: `${proposalId}`,
    });
  }

  public collectFilterApplied(filterId: string, selectedValue: string): void {
    this.buffer.push({
      activity: selectedValue,
      subject: 'FILTER',
      subjectId: filterId,
    });
  }

  public collectFilterCleared(filterId: string): void {
    this.buffer.push({
      activity: 'CLEARED',
      subject: 'FILTER',
      subjectId: filterId,
    });
  }

  /**
   * For some metrics, we don't want to push several times,
   * for that this will avoid sending the same metric more than once.
   */
  private pushCached(stat: Stat) {
    if (this.cache[stat.subject] === undefined) {
      // Add default empty subject metrics
      this.cache[stat.subject] = {};
    }

    if (this.cache[stat.subject][stat.activity] === undefined) {
      // Add a default empty array when it is missing
      this.cache[stat.subject][stat.activity] = [];
    }

    if (this.cache[stat.subject][stat.activity].includes(stat.subjectId)) {
      // If the metric was already cached, then do not push it again
      return;
    }

    // Cache the metric and push to the buffer
    this.cache[stat.subject][stat.activity].push(stat.subjectId);
    this.buffer.push(stat);
  }
}
